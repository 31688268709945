<template>
  <v-container fluid
    >
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="refundEmailArToApSubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to record Refund Email AR to AP date.
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalRefundEmailArToApSubmit()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="refundEmailArToApSubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="revertCaseDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Revert Case
        </v-toolbar>

        <v-form lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-label
                  ><span class="text-danger">*</span>Please confirm if you wish
                  to reject the refund & add a remarks:
                </v-label>
                <v-textarea
                  filled
                  no-resize
                  name="input-7-4"
                  v-model="rejectReason"
                  :error-messages="errorField.rejectReason"
                ></v-textarea>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red lighten-1"
              class="text-light"
              @click="revertCaseDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="green lighten-1"
              class="text-light"
              @click="revertCase()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span
            v-if="isFinanceArAdminRoles && !btnDisabled && e1 == 1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="revertCaseDialog = true"
                  color="orange lighten-2"
                >
                  <v-icon dark v-bind="attrs" v-on="on"> mdi-file-undo </v-icon>
                </v-btn>
              </template>
              <span>Revert Case</span>
            </v-tooltip>
          </span>

          <span
            v-if="isFinanceArAdminRoles && !btnDisabled && e1 == 3"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="refundEmailArToApSubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Submit Refund Email AR to AP</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            Arrange Refund Sequence
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            Upload Attachments
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"> Preview Compiled PDF </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <!-- Company Name -->
            <v-row>
              <v-col cols="12" sm="6" md="6" class="ma-0">
                <v-text-field
                  dense
                  filled
                  readonly
                  label="Company Name"
                  v-model="formData.companyName"
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.companyName)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Client Invoice -->
            <v-row>
              <v-col cols="12" sm="6" md="6" class="ma-0">
                <v-text-field
                  dense
                  filled
                  readonly
                  label="Client Invoice No."
                  v-model="formData.clientInvoiceNo"
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.clientInvoiceNo)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- HRDC Invoice -->
            <v-row>
              <v-col cols="12" sm="6" md="6" class="ma-0">
                <v-text-field
                  dense
                  filled
                  readonly
                  label="HRDC Invoice No."
                  v-model="formData.hrdcInvoiceNo"
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.hrdcInvoiceNo)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Client Invoice -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  filled
                  readonly
                  label="Credit Note No."
                  v-model="formData.creditNoteNo"
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.creditNoteNo)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- Refund Compiled PDF Sequence -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formData.refundPdfSequence"
                  :items="refundPdfSequence"
                  filled
                  label="Refund PDF Sequence"
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-btn
              class="text-white"
              :color="HRDC_BLUE_THEME_COLOR"
              @click="goto2ndStepper()"
              :disabled="!this.isFinanceArAdminRoles"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <!-- OR HRDF -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="
                    addMultipleDropFile($event, 'officialReceiptHrdc')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="OR HRDF"
                    accept=".pdf"
                    v-model="formData.officialReceiptHrdc"
                    :error-messages="errorField.officialReceiptHrdc"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- Bank Statement HRDF -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="
                    addMultipleDropFile($event, 'bankStatementHrdc')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="Bank Statement HRDF (CIMB)"
                    accept=".pdf"
                    v-model="formData.bankStatementHrdc"
                    :error-messages="errorField.bankStatementHrdc"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- OR / SOA -->
            <v-row>
              <v-col cols="12" sm="6" md="6" class="pb-0">
                <div
                  v-cloak
                  @drop.prevent="
                    addMultipleDropFile($event, 'officialReceiptAndSOA')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="OR / SOA"
                    accept=".pdf"
                    v-model="formData.officialReceiptAndSOA"
                    :error-messages="errorField.officialReceiptAndSOA"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <hr />

            <!-- Bank Statement for invoice client (CIMB) -->
            <v-row
              v-if="
                [REFUND_LIST_1_PAYMENT_VIA_CIMB].includes(
                  formData.refundPdfSequence
                )
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="
                    addMultipleDropFile($event, 'bankStatementForInvoiceClient')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="Bank Statement for Invoice Client"
                    accept=".pdf"
                    v-model="formData.bankStatementForInvoiceClient"
                    :error-messages="errorField.bankStatementForInvoiceClient"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- Stripe In -->
            <v-row
              v-if="
                [REFUND_LIST_2_PAYMENT_VIA_STRIPE].includes(
                  formData.refundPdfSequence
                )
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="addMultipleDropFile($event, 'stripeIn')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="Stripe In"
                    accept=".pdf"
                    v-model="formData.stripeIn"
                    :error-messages="errorField.stripeIn"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- Stripe Payout -->
            <v-row
              v-if="
                [REFUND_LIST_2_PAYMENT_VIA_STRIPE].includes(
                  formData.refundPdfSequence
                )
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="addMultipleDropFile($event, 'stripePayout')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="Stripe Payout"
                    accept=".pdf"
                    v-model="formData.stripePayout"
                    :error-messages="errorField.stripePayout"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- iPay88 -->
            <v-row
              v-if="
                [REFUND_LIST_3_PAYMENT_VIA_IPAY88].includes(
                  formData.refundPdfSequence
                )
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="addMultipleDropFile($event, 'iPay88')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="iPay88"
                    accept=".pdf"
                    v-model="formData.iPay88"
                    :error-messages="errorField.iPay88"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- iPay88 Payout -->
            <v-row
              v-if="
                [REFUND_LIST_3_PAYMENT_VIA_IPAY88].includes(
                  formData.refundPdfSequence
                )
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="addMultipleDropFile($event, 'iPay88Payout')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="iPay88 Payout"
                    accept=".pdf"
                    v-model="formData.iPay88Payout"
                    :error-messages="errorField.iPay88Payout"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <!-- Bank Statement CIMB (Payout) -->
            <v-row
              v-if="
                [
                  REFUND_LIST_2_PAYMENT_VIA_STRIPE,
                  REFUND_LIST_3_PAYMENT_VIA_IPAY88,
                ].includes(formData.refundPdfSequence)
              "
            >
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="
                    addMultipleDropFile($event, 'bankStatementCimbPayout')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    multiple
                    dense
                    filled
                    small-chips
                    label="Bank Statement CIMB Payout"
                    accept=".pdf"
                    v-model="formData.bankStatementCimbPayout"
                    :error-messages="errorField.bankStatementCimbPayout"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>

            <v-btn color="warning" class="text-white" @click="e1 = 1">
              Back
            </v-btn>
            &nbsp;
            <v-btn
              class="text-white"
              :color="HRDC_BLUE_THEME_COLOR"
              @click="goto3rdStepper()"
              :disabled="!this.isFinanceArAdminRoles"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <!-- Preview Compiled PDF -->
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div
                  v-cloak
                  @drop.prevent="addSingleDropFile($event, 'compiledPdf')"
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    label="Compiled PDF Attachment"
                    accept=".pdf"
                    v-model="formData.compiledPdf"
                    :error-messages="errorField.compiledPdf"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn
                  x-large
                  color="#063058"
                  outlined
                  @click="previewCompiledPdf('compiledPdf')"
                >
                  Preview
                </v-btn>
              </v-col>
            </v-row>

            <!-- TO Receipient -->
            <v-col class="d-flex1 pa-0 d-none" cols="6" sm="6" md="6">
              <v-combobox
                dense
                filled
                label="TO Recipient"
                v-model="formData.toEmail"
                :error-messages="errorField.toEmail"
                :hide-selected="true"
                multiple
                small-chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                    <v-icon small @click="data.parent.selectItem(data.item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>

                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-information </v-icon>
                    </template>
                    Press <kbd>enter/tab</kbd> to insert multiple email
                  </v-tooltip>
                </template></v-combobox
              >
            </v-col>

            <!--CC Related PIC (Top Management)-->
            <v-col class="d-flex1 pa-0 d-none" cols="6" sm="6" md="6">
              <v-combobox
                dense
                filled
                label="CC Recipient"
                v-model="formData.ccEmail"
                :error-messages="errorField.ccEmail"
                :hide-selected="true"
                multiple
                small-chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                    <v-icon small @click="data.parent.selectItem(data.item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>

                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-information </v-icon>
                    </template>
                    Press <kbd>enter/tab</kbd> to insert multiple email
                  </v-tooltip>
                </template></v-combobox
              >
            </v-col>

            <v-btn color="warning" class="mt-3 text-white" @click="e1 = 2">
              Back
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiCompilePdf: new Api(),
    apiValidateAttachmentAndCompile: new Api(),
    apiGetArToApEmailDetails: new Api(),
    apiRejectApplicationToTaxpodAdmin: new Api(),
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      refundEmailArToApDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      officialReceiptHrdc: [],
      officialReceiptAndSOA: [],
      bankStatementHrdc: [],
      bankStatementForInvoiceClient: [],
      refundPdfSequence: 1,
      compiledPdf: null,
      compiledPdfName: null,
      stripeIn: [],
      stripePayout: [],
      iPay88: [],
      iPay88Payout: [],
      eGHL: [],
      bankStatementCimbPayout: [],
      toEmail: [],
      ccEmail: [],
      emailContent: null,
      hrdcInvoiceNo: null,
      creditNoteNo: null,
      clientInvoiceNo: null,
      companyName: null,
    },
    errorField: {
      applicationNo: null,
      refundEmailArToApDate: null,
      officialReceiptHrdc: null,
      officialReceiptAndSOA: null,
      bankStatementHrdc: null,
      bankStatementForInvoiceClient: null,
      stripeIn: null,
      stripePayout: null,
      iPay88: null,
      iPay88Payout: null,
      eGHL: null,
      bankStatementCimbPayout: null,
      toEmail: null,
      ccEmail: null,
      emailContent: null,
      compiledPdf: null,
      rejectReason: null,
    },
    loadingDialog: false,
    refundEmailArToApSubmitDialog: false,
    revertCaseDialog: false,
    rejectReason: null,
    editMode: false,
    btnDisabled: false,
    e1: 1,
    refundPdfSequence: [
      {
        text: "Refund List 1 - Default (CIMB)",
        value: 1,
      },
      {
        text: "Refund List 2 - Payment via Stripe",
        value: 2,
      },
      {
        text: "Refund List 3 - Payment via iPay88",
        value: 3,
      },
    ],
    REFUND_LIST_1_PAYMENT_VIA_CIMB: 1,
    REFUND_LIST_2_PAYMENT_VIA_STRIPE: 2,
    REFUND_LIST_3_PAYMENT_VIA_IPAY88: 3,
    // Statc Attachments is all 4 sequence must include.
    staticAttachments: [
      "officialReceiptHrdc",
      "officialReceiptAndSOA",
      "bankStatementHrdc",
    ],
    // Dynamic Attachments is depends on sequence.
    dynamicAttachments: [
      "bankStatementForInvoiceClient",
      "stripeIn",
      "stripePayout",
      "bankStatementCimbPayout",
      "iPay88",
      "iPay88Payout",
      "eGHL",
    ],
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.formData.emailContent = `
      <span style="font-family: 'Arial'; font-size: 10px" data-mce-style="font-family: 'Arial'; font-size: 10px">
        Dear AP Team, <br><br>
        Please assist to proceed on this refund. <br><br>
        Thank you.<br>
        Best Regards, <br><br>
      </span>
      <span style="font-family: 'Brush Script MT'; font-size: 28px" data-mce-style="font-family: 'Brush Script MT'; font-size: 28px">
        ${this.emailSignatureName()}
      </span>
      <br>
      <span style="font-family: 'Arial'; font-size: 10px" data-mce-style="font-family: 'Arial'; font-size: 10px">
        <b>${
          this.auth.name
        } | YYC Holdings Sdn. Bhd. | Finance Department</b><br>
        <a href="mailto:${this.auth.email}">${this.auth.email}</a> | ${
      this.auth.mobile
    }
      </span>
    `;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_ar_to_ap/create-refund-email-ar-to-ap`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiCompilePdf: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_ar_to_ap/compile-pdf`,
        method: "post",
      },
      apiValidateAttachmentAndCompile: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_ar_to_ap/validate-attachment-and-compile`,
        method: "post",
      },
      apiGetArToApEmailDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_ar_to_ap/get-ar-to-ap-email-details`,
        method: "post",
      },
      apiRejectApplicationToTaxpodAdmin: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/refund_email_ar_to_ap/reject-application-to-taxpod-admin`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc_tax_summit.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isFinanceArAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGreater(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApToCimbRejected
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.refundEmailApproval2
    );

    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          this.formData.companyName = data.oriCompanyName;
          this.formData.hrdcInvoiceNo = data.manualInvoiceToHrdc;
          this.formData.creditNoteNo = data.creditNoteNo;
          this.formData.clientInvoiceNo = data.autoGeneratedClientInvoiceNo;

          const companyName = data.oriCompanyName.toUpperCase();
          const claimAmount = `RM${this.formatNumber(data.totalPrice)}`;
          this.formData.compiledPdfName = `COMPILED PDF - ${companyName} - REFUND_${claimAmount}.pdf`;

          const refundEmailArToApStageId =
            this.hrdcData.stagePriority.refundEmailArToAp;

          const refundArToApObj = data.ApplicationLog.find(
            (obj) => obj.stageId == refundEmailArToApStageId
          );

          if (refundArToApObj) {
            this.formData.refundEmailArToApDate = moment(
              refundArToApObj.startDate
            ).format("YYYY-MM-DD");
          }
        }

        this.loadingDialog = !this.loadingDialog;
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationSingleDetail.fetch();

    // Get email receipient.
    this.apiGetArToApEmailDetails.setParams({
      emailToSsoRole: ["hrdc-taxpod-refund-email-to-ap-recipient"],
      emailToUserGroupRole: ["finance-ap-group"],
      emailCcSsoRole: ["hrdc-taxpod-refund-email-cc-ap-recipient"],
      emailCcUserGroupRole: ["finance-ar-group", "learn-taxpod"],
    });
    this.apiGetArToApEmailDetails.setCallbackCompleted((response) => {
      try {
        const { data, status } = response;
        if (status) {
          // Recipient Details.
          const toEmailItems = data.toRecipient;
          const ccEmailItems = data.ccRecipient;

          // Append to recipient.
          for (const toEmail of toEmailItems) {
            this.formData.toEmail.push(toEmail.email);
          }

          // Append cc recipient.
          for (const ccEmail of ccEmailItems) {
            this.formData.ccEmail.push(ccEmail.email);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetArToApEmailDetails.fetch();
  },
  mounted() {},
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    clearAttachments(attachments) {
      for (const atch of attachments) {
        this.formData[atch] = [];
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    //EOC
    modalRefundEmailArToApSubmit() {
      this.refundEmailArToApSubmitDialog = !this.refundEmailArToApSubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tax_summit.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    goto2ndStepper() {
      this.e1 = 2;

      // Clear all previous error messages.
      this.clearErrorMsg();

      // Clear all dynamic attachments.
      this.clearAttachments(this.dynamicAttachments);
    },
    goto3rdStepper() {
      this.loadingDialog = !this.loadingDialog;

      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("refundPdfSequence", this.formData.refundPdfSequence);

      for (const sAtch of this.staticAttachments) {
        for (let i = 0; i < this.formData[sAtch].length; i++) {
          fd.append(sAtch, this.formData[sAtch][i]);
        }
      }

      for (const dAtch of this.dynamicAttachments) {
        for (let j = 0; j < this.formData[dAtch].length; j++) {
          fd.append(dAtch, this.formData[dAtch][j]);
        }
      }

      this.apiValidateAttachmentAndCompile.setParams(fd);
      this.apiValidateAttachmentAndCompile.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          this.clearErrorMsg();
          if (!status) {
            this.showErrorMessage(response);
            return;
          }

          if (status == "No_APPROVAL_FOUND") {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
            });
            return;
          }

          if (status) {
            try {
              const blob = new Blob([new Uint8Array(data)]);
              this.formData.compiledPdf = new File(
                [blob],
                this.formData.compiledPdfName,
                {
                  type: "application/pdf",
                }
              );
            } catch (err) {
              console.log(err);
            }
            this.e1 = 3;
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.loadingDialog = !this.loadingDialog;
        }
      });
      this.apiValidateAttachmentAndCompile.fetch();
    },
    formatNumber(number) {
      if (number) {
        return Number(number).toLocaleString();
      }
    },
    emailSignatureName() {
      let signature = this.auth.name.split(" ").slice(0, 2).join(" ");
      if (this.auth.signatureName) {
        signature = this.auth.signatureName;
      }
      return signature;
    },
    addMultipleDropFile(e, key) {
      let file = e.dataTransfer.files;

      for (const fileItem of file) {
        if (!fileItem.type.match("pdf.*")) {
          this.errorField[key] = "Please select pdf file format";
          return;
        }

        this.formData[key].push(fileItem);
      }

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addSingleDropFile(e, key) {
      let file = e.dataTransfer.files[0];

      if (!file.type.match("pdf.*")) {
        this.errorField[key] = "Please select pdf file format";
        return;
      }

      this.formData[key] = file;

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    previewCompiledPdf(fileType) {
      if (!this.formData[fileType]) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      switch (fileType) {
        default:
          url = URL.createObjectURL(this.formData[fileType]);
          newWindow = window.open(url, "_blank");
          break;
      }

      if (newWindow) {
        newWindow.onload = function () {
          // Revoke the object URL when the window is closed
          newWindow.addEventListener("beforeunload", function () {
            URL.revokeObjectURL(url);
          });
        };
      }
    },
    revertCase() {
      this.showLoadingDialog();

      this.clearErrorMsg();

      this.apiRejectApplicationToTaxpodAdmin.setParams({
        userUuid: this.auth.uuid,
        revertBackToStageId: this.hrdcData.stagePriority.refundEmailApproval1,
        refundEmailArToApRejectedStageId:
          this.hrdcData.stagePriority.refundEmailArToApRejected,
        applicationUuid: this.$route.query._ausk,
        rejectReason: this.rejectReason,
      });
      this.apiRejectApplicationToTaxpodAdmin.setCallbackCompleted(
        (response) => {
          try {
            if (!response.status) {
              this.showErrorMessage(response);
              this.hideLoadingDialog();
            }

            if (response.status) {
              this.revertCaseDialog = false;
              this.rejectReason = null;

              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "success",
                timeout: 2000,
              });

              this.$router.push({
                name: "HrdcTaxSummitApplication",
                params: {
                  modelKey: this.model.key,
                  backTo: "",
                },
                query: {
                  viewId: this.$store.state.hrdc_tax_summit.data.viewId,
                  _ausk: this.$route.query._ausk,
                },
              });

              this.hideLoadingDialog();
            }
          } catch (err) {
            console.log(err);
            this.alertError.push(err.message);
            return false;
          }
        }
      );
      this.apiRejectApplicationToTaxpodAdmin.fetch();
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;

      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("compiledPdf", this.formData.compiledPdf);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("emailContent", this.formData.emailContent);
      fd.append("refundEmailArToApDate", this.formData.refundEmailArToApDate);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcTaxSummitApplication",
              query: {
                viewId: this.$store.state.hrdc_tax_summit.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
